import React, { useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import { useAuthAPI } from '../hooks/api.hooks'
import Context from '../context'
import getConfig from 'next/config'
import ProductPreviewCard from './ProductPreviewCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Pagination, FreeMode, Navigation } from 'swiper'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/keyboard'
import { searchTermAnalytics } from '../utils/analytics'
import SVG from './SVG'

const { publicRuntimeConfig } = getConfig()

const RecentSearchResults = () => {
  const { state, dispatch } = useContext(Context)
  const router = useRouter()
  const authAPICall = useAuthAPI()

  const swiperConfig = {
    modules: [FreeMode, Pagination, Navigation, Keyboard],
    freeMode: true,
    pagination: { clickable: true },
    navigation: false,
    spaceBetween: 16,
    keyboard: true,
    watchOverflow: true
  }

  useEffect(() => {
    if (state.recentlyViewed.some(product => product.store !== state.cart.store?.storenumber)) {
      let recentProductsCopy = state.recentlyViewed
      recentProductsCopy = recentProductsCopy.length && recentProductsCopy.filter(product => product.upc)
      const upcList = recentProductsCopy.length && recentProductsCopy.map(item => item.upc)?.join(',')

      authAPICall(
        'productinfo',
        'products',
        'GET',
        {
          popkey: publicRuntimeConfig.POP_KEY,
          store: state.cart.store?.storenumber || publicRuntimeConfig.DEFAULT_STORE,
          upcs: upcList
        },
        {},
        { deviceid: state.deviceId || '' }
      ).then((data) => {
        data.forEach(product => {
          const index = recentProductsCopy?.findIndex(e => +e.upc === +product.upc)
          if (index > -1) {
            recentProductsCopy[index].price = product.price
            recentProductsCopy[index].discount_price = product.discount_price
            recentProductsCopy[index].store = state.cart.store?.storenumber
          }
        })
        dispatch({ type: 'SET_RECENTLY_VIEWED', payload: recentProductsCopy })
      })
    }
  }, [state.cart.store?.storenumber])

  return (
    <div className="recently_viewed_search">
      {!!state.recentlySearched.length && (
        <>
          <h3>Recently Searched</h3>
          <div className="recent_search recent_search_results">
            <ul>
              {state.recentlySearched.map((term) => (
                <li
                  className='recent__search-term'
                  onClick={() => {
                    searchTermAnalytics(term.cleanedSearch)
                    router.push(`/search/${term.cleanedSearch}`)
                    const searchData = {
                      q: ''
                    }
                    searchData.q = term.cleanedSearch
                    try {
                      window.BrTrk.getTracker().logEvent('suggest', 'submit', searchData, {}, true)
                    } catch (e) {
                      console.log(e)
                    }
                  }}
                  key={term.cleanedSearch}
                >
                  <button className="search_term_header">{term.cleanedSearch}</button>
                  <SVG name="search" label="search" size="base"></SVG>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}

      {!!state.recentlyViewed.length && (
        <>
          <h3>Recently Viewed</h3>
          <section className='swiper__section'>
            <Swiper {...swiperConfig} slidesPerView={'auto'}>
              {state.recentlyViewed.slice(0, 5).map(product => (
                <SwiperSlide key={product.slug}>
                  <ProductPreviewCard data={{ ...product, image_urls: product.image, product_name: product.title }}/>
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
        </>
      )}
    </div>
  )
}

export default RecentSearchResults
