import React from 'react'

const SkipToMain = ({ ionRef }) => {
  return (
      <button
        className="skip-to-main"
        onClick={() => ionRef.current.scrollIntoView({ behavior: 'smooth' })}
      >
        skip to main content
      </button>
  )
}

export default SkipToMain
