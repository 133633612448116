import React from 'react'
import SVG from './SVG'

const CartChangedModal = ({ handleDismiss }) => {
  return (
    <section className="cart-changed-modal">
      <button onClick={handleDismiss} className="close"><SVG name="close" label="Close" size="md" /></button>
      <div className="cart-changed-modal__bag">
        <SVG name="bag" label="bag" size="xl" />
      </div>
      <p>Some items in your cart have changed. See your cart for details.</p>
      <button className="btn__primary" onClick={handleDismiss}>
        CLOSE
      </button>
    </section>
  )
}

export default CartChangedModal
