import React, { useReducer, useContext } from 'react'
import Context from './context'
import reducer from './reducer'

const Provider = (props) => {
  const initialState = useContext(Context)
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <Context.Provider value={{ state, dispatch }}>
      {props.children}
    </Context.Provider>
  )
}

export default Provider
