import React, { useContext } from 'react'
import Context from '../context'
import SVG from './SVG'

const StoreClosedModal = ({ handleDismiss = null, goToStoreSelector = false }) => {
  const { state } = useContext(Context)

  if (goToStoreSelector) {
    return (
      <section>
        <button className="close" onClick={handleDismiss}><SVG name="close" label="Close" size="md" /></button>
        <SVG name="alert-full" label="alert-full" size="xl" />
        <h6>Sorry, this store is closed.</h6>
        <p>Please return to your order and choose another store that is available for your order.</p>
        <button className="btn__primary change-store-button" onClick={handleDismiss}>
          change store
        </button>
      </section>
    )
  } else {
    return (
      <section>
        <SVG name="alert-full" label="alert-full" size="xl" />
        <h6 className="bold-modal-text">Store temporarily closed</h6>
        <p>The store you are shopping is temporarily closed</p>
        <p>
          {state.cart.store?.address},<br />
          {state.cart.store?.city} {state.cart.store?.state}
        </p>
        <p>
          Feel free to build a cart for pickup at this store, but note that you will be unable to check out a pickup
          order until the store re-opens. Check back soon.
        </p>
        <button className="btn__primary" onClick={handleDismiss}>
          got it
        </button>
      </section>
    )
  }
}

export default StoreClosedModal
