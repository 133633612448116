import { useContext, useState, useEffect } from 'react'
import Context from '../context'
import { useAddToCart } from '../hooks/cart.hooks'
import { useUnauthAPI } from '../hooks/api.hooks'
import getConfig from 'next/config'
import StoreSearch from '../components/StoreSearch'
import StoreClosedModal from './StoreClosedModal'
import SVG from './SVG'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from 'next/image'
import 'swiper/css'

const { publicRuntimeConfig } = getConfig()

const ConfirmStore = ({ upc, sku, digitalSku, handleDismiss }) => {
  const { state } = useContext(Context)
  const makeAddToCartCall = useAddToCart()
  const makeUnauthAPICall = useUnauthAPI()
  const [eventRef, setEventRef] = useState(null)

  const [swiper, setSwiper] = useState(null)
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${state.cart.store?.latitude},${state.cart.store?.longitude}&zoom=18&size=600x400&scale=2&markers=color:red&markers=size:mid&markers=scale:2|${state.cart.store?.latitude},${state.cart.store?.longitude}&scale=2&key=${publicRuntimeConfig.MAPS_API_KEY}`

  useEffect(() => {
    try {
      const modalRef = document.querySelector('.modal--fullscreen') as any
      if (modalRef?.event) {
        setEventRef(modalRef.event)
      }
    } catch (e) {
      console.warn(e)
    }
  }, [])

  const onConfirm = async () => {
    const data = await makeUnauthAPICall(
      'stores',
      'store',
      'GET',
      {
        id: state.cart.store?.storenumber,
        popkey: publicRuntimeConfig.POP_KEY
      },
      {}
    )

    const isClosed = data[0]?.is_closed

    if (isClosed) {
      swiper.slideTo(2)
    }

    makeAddToCartCall(state.cart.store?.storenumber || publicRuntimeConfig.DEFAULT_STORE, upc, digitalSku, sku, 1, true, eventRef).then((resData) => {
      if (!isClosed) {
        const el = document.querySelector('.add-to-cart__hidden-div')
        if (el) {
          el.textContent = 'product is added to cart'
        }
        handleDismiss()
      }
    })
  }

  const swiperConfig = {
    modules: [],
    spaceBetween: 0,
    keyboard: false,
    watchOverflow: true,
    pagination: false,
    navigation: false,
    allowTouchMove: false,
    slidesPerView: 1,
    observer: true,
    observeParents: true
  }

  return (
    <div className="new-user">
      <Swiper {...swiperConfig} onSwiper={setSwiper}>
        <SwiperSlide>
          <section>
            <button onClick={handleDismiss} className="close"><SVG name="close" label="Close" size="md" /></button>
            <p className="new-user__header">You're starting a Pickup order.</p>
            <h1>pOpshelf&#174; offers free in-store pickup for online orders. Shipping will be available in the future.</h1>
            <div className="new-user__card">
              <div className='maps__confirm-store'>
                <Image src={mapUrl} height={0} width={0} alt='Maps' layout="fill" objectFit="cover" />
              </div>
              {/* <div className="map-area" id="map__modal" ref={(newRef) => setRef(newRef)} /> */}
            </div>
            <h2>Confirm your store: </h2>
            <button className="new-user__address" onClick={() => swiper.slidePrev()}>
              <span>{state.cart.store?.address},</span><br />
              <span>{state.cart.store?.city}, {state.cart.store?.state}</span>
            </button>
            <button className='btn__primary' onClick={onConfirm}>
              CONFIRM STORE
            </button>
            <button className="btn__secondary" onClick={() => swiper.slideNext()}>
              CHANGE STORE
            </button>
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <StoreSearch handleDismiss={handleDismiss} cartAddData={{ upc, sku, digitalSku }} sku={sku} />
        </SwiperSlide>
        <SwiperSlide>
          <StoreClosedModal handleDismiss={handleDismiss} />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default ConfirmStore
