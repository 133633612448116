/* eslint-disable no-unused-vars */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable func-style */
import { useContext, useState } from 'react'
import Context from '../context'
import getConfig from 'next/config'
import router from 'next/router'
import { useIonModal, useIonPopover } from '@ionic/react'
import { useAuthAPI } from '../hooks/api.hooks'
import ConfirmStore from '../components/ConfirmStore'
import Popover from '../components/Popover'
import CartChangedModal from '../components/CartChangedModal'
import { getCookie, writeCookie } from '../utils/writeCookie'
const { publicRuntimeConfig } = getConfig()

export enum ErrorCodes {
  ITEMS_CHANGED
}

export function useAddToCart () {
  const { state, dispatch } = useContext(Context)
  const [stateUPC, setUPC] = useState('')
  const [stateSKU, setSKU] = useState('')
  const [stateDigitalSKU, setDigitalSKU] = useState('')
  const [availableProducts, setAvailableProducts] = useState()
  const productStock = Math.min(availableProducts, 20)
  const [altMsg, setAltMsg] = useState(false)

  const [presentPopover, dismissPopover] = useIonPopover(Popover, { onHide: () => dismissPopover(), tooltipMsg: `You've reached the maximum capacity (${productStock}) allowed for this item.`, tooltipMsgAlt: 'You have reached the maximum capacity allowed for this item.', altMsg })
  const handleDismiss = () => {
    dismiss()
  }
  const [present, dismiss] = useIonModal(ConfirmStore, {
    upc: stateUPC,
    sku: stateSKU,
    digitalSku: stateDigitalSKU,
    handleDismiss
  })

  const makeAPICall = useAuthAPI()

  const getCartQuantity = () => {
    let total = 0
    if (state.cart.products.length === 0) {
      return total
    } else {
      for (let i = 0; i < state.cart.products.length; i++) {
        total += parseInt(state.cart.products[i].quantity)
      }
      return total
    }
  }

  const addToCart = async (store, upc, digitalSku, sku, quantity, override = false, e = null) => {
    setUPC(upc); setDigitalSKU(digitalSku); setSKU(sku)
    if (!override && (!state.cart.products?.length || getCookie('needToConfirmStore'))) {
      writeCookie('needToConfirmStore', false, 0)
      present({ cssClass: 'modal--fullscreen', htmlAttributes: { event: e } })
      return {}
    } else {
      const authPathnames = ['/login', '/registration']
      if (!authPathnames.includes(router.pathname)) {
        try {
          window.BrTrk.getTracker().logEvent('cart', 'click-add', { prod_id: digitalSku, sku: sku })
        } catch (e) {
          console.log(e)
        }
      }

      // POST CALL
      const postBody = {
        store: store,
        upc: upc,
        quantity: quantity,
        shoppingListTypeId: 3
      }

      const data = await makeAPICall('user', 'cart', 'POST', {}, postBody)
      if (data?.error) {
        if (data.error === '8da13029-2be8-4007-93ef-45e2d5025f15') {
          if (state.cart?.products.some(prod => prod.upc === +upc)) {
            setAltMsg(false)
          } else {
            setAltMsg(true)
          }
          presentPopover({ event: e?.nativeEvent, side: 'top', mode: 'ios', showBackdrop: false, cssClass: 'tooltipCart', reference: 'event', alignment: 'center' })

          const findAvailableStock = state.cart.products.find(product => +upc === +product.upc)
          setAvailableProducts(findAvailableStock?.availableStock)
        } else if (data.error === '2548a7f3-7bf4-4533-a6c1-dcbcfcdc26a5') {
          dismiss()
        }
        return data.error
      } else if (data?.shoppingListId) {
        if (typeof window !== 'undefined') {
          // Determining if it was add, increment, remove, or decrement
          const stateProduct = state.cart.products.find((item) => item.upc === parseInt(upc))
          let modifiedProduct = data.products.find((item) => item.upc === parseInt(upc))
          let total = 0.0
          let event = 'add-to-cart'
          let fulfillment = 'pickup'

          if (!modifiedProduct) {
            // remove event
            modifiedProduct = state.cart.products.find((item) => item.upc === parseInt(upc))
            total = state.cart.subtotal - modifiedProduct.finalPrice
            event = 'remove-cart'
            if (state.cart.products.length === 1) {
              fulfillment = 'unset'
            }
          } else {
            // decrement and increment events
            total = data.subtotal
            if (stateProduct) {
              if (parseInt(stateProduct.quantity) > parseInt(modifiedProduct.quantity)) {
                event = 'decrement-cart'
              } else {
                event = 'increment-cart'
              }
            }
          }

          // ANALYTICS cart-change
          try {
            (window as any)._satellite.track('cart-change', {
              productName: modifiedProduct.description.toLowerCase(),
              upc,
              qty:
                event === 'decrement-cart' || event === 'remove-cart'
                  ? getCartQuantity() - 1 || '0'
                  : getCartQuantity() + 1,
              total: total ? total.toFixed(2) : '0.00',
              event,
              fulfillment
            })
          } catch (e) {
            console.warn(e)
          }
        }
        dispatch({ type: 'UPDATE_CART', payload: data })
        return data
      } else {
        dismiss()
        return {
          error: 'No return value'
        }
      }
    }
  }

  return addToCart
}

export function useChangeCartStore () {
  const { state, dispatch } = useContext(Context)

  const makeAPICall = useAuthAPI()

  const handleDismiss = () => {
    dismiss()
  }
  const [present, dismiss] = useIonModal(CartChangedModal, {
    handleDismiss
  })

  const changeCartStore = async (store) => {
    const data = await makeAPICall('user', 'cart', 'PUT', {}, {
      store: store,
      shoppingListTypeId: 3
    })
    if (data?.error) {
      return data.error
    } else if (data?.shoppingListId) {
      if (state.cart?.shoppingListId && state.cart?.products?.length) {
        dispatch({ type: 'UPDATE_CART', payload: data })
        for (const product of data.products) {
          const matchingProduct = state.cart?.products?.find((prod) => prod.upc === product.upc)
          if (
            !matchingProduct ||
              product.availableStock < matchingProduct.quantity ||
              product.finalPrice !== matchingProduct.finalPrice ||
              product.price !== matchingProduct.price
          ) {
            present()
            data.error = ErrorCodes.ITEMS_CHANGED
            return
          }
        }
      }
      dispatch({ type: 'UPDATE_CART', payload: data })
      return data
    }
  }

  return changeCartStore
}

export function useGetCart () {
  const { state, dispatch } = useContext(Context)
  const makeAPICall = useAuthAPI()
  const changeCartCall = useChangeCartStore()

  const getCart = async () => {
    const data = await makeAPICall('user', 'cart', 'GET', {}, {})
    if (data?.error) {
      return data.error
    } else if (data?.shoppingListId) {
      dispatch({ type: 'UPDATE_CART', payload: data })
      return data
    } else {
      changeCartCall(publicRuntimeConfig.DEFAULT_STORE)
      return data
    }
  }

  return getCart
}
