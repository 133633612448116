/* eslint-disable @next/next/no-img-element */
import React, { useContext, useEffect, useState } from 'react'
import Link from 'next/link'
import SVG from './SVG'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import Context from '../context'
import { useUnauthAPI } from '../hooks/api.hooks'

const currentYear = dayjs(new Date()).format('YYYY')

interface IFooterTemplate {
  logoPath?: string,
  dynamicMediaUrl?: string,
  column1Links?: any[],
  column2Links?: any[],
}
const Footer = () => {
  const [footerTemplate, setFooterTemplate] = useState<IFooterTemplate>({})
  const { state } = useContext(Context)
  const makeAPICall = useUnauthAPI()
  const authenticated = state.user.authType
  const router = useRouter()

  useEffect(() => {
    makeAPICall('admin', 'footer', 'GET', {}, {})
      .then((response) => {
        const footerData = response && response[0].template ? JSON.parse(response[0].template)[0] : {}
        setFooterTemplate(footerData)
      })
  }, [])

  const isAutheticationPath = router.pathname === '/login' ||
    router.pathname === '/registration' ||
    router.pathname === '/forgotpassword' ||
    router.pathname === '/popshelf' ||
    router.pathname === '/newdevice'

  const openCookiePrefsModal = () => {
    window.claripCookieManager.setToggle()
    window.claripCookieManager.showClaripCookieManager()
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter') {
      openCookiePrefsModal()
    }
  }

  const handleLinks = (text, path) => {
    if (text === 'Track Your Order') {
      return authenticated ? path : '/order'
    } else {
      return path
    }
  }

  const renderLink = (link) => {
    if (link.elementId === 'cookie-preferences') {
      return (
        <a
          tabIndex={0}
          onClick={openCookiePrefsModal}
          onKeyDown={onKeyDown}
        >
          {link.text}
        </a>
      )
    } else if (link.elementId === 'clarip-do-not-sell-link') {
      return (
        <>
          <div className="footer-page-link-content" id={link.elementId} tabIndex={0} aria-label={link.text} role="link">
            {link.text}
          </div>
          <img src="/images/icons/opt-out.png" alt="CCPA Opt-Out Icon" />
        </>
      )
    } else {
      return (link.openInNewTab === 'true'
        ? (<a
          href={link.path}
          target={'_blank'}
          rel={'norefferer noreferrer'}
        >
          {link.text}
        </a>)
        : (<a
          href={handleLinks(link.text, link.path)}
          target={'_self'}
          rel={''}
        >
          {link.text}
        </a>
          ))
    }
  }

  return (
    <>
      <footer className={`footer footer--auth${!isAutheticationPath ? ' footer--hide' : ''}`}>
        <div>
          <Link href="/termsandconditions">
            <a tabIndex={0} target="_blank">
              Terms &amp; Conditions
            </a>
          </Link>
          <Link href="/privacypolicy">
            <a tabIndex={0} target="_blank">
              Privacy Policy
            </a>
          </Link>
          <a
            tabIndex={0}
            id="clarip-do-not-sell-link"
            href="javascript:void(0)"
          >
            Do Not Sell My Info
          </a>
        </div>
        <p>© Copyright {currentYear}. pOpshelf&#174; All rights reserved.</p>
      </footer>
      <footer className={`footer footer--std${isAutheticationPath ? ' footer--hide' : ''}`}>
        <div className='footer__top'>
          <div className='footer__section'>
            <Link href="/">
              <a>
                <img src={footerTemplate?.dynamicMediaUrl || footerTemplate?.logoPath} className="footer__brand" alt="Popshelf logo" />
              </a></Link>
            <div className="footer__social-media">
              <Link href="https://www.facebook.com/popshelf" prefetch={false}>
                <a target="_blank" className="footer__social-media-icon" aria-label="Popshelf's page on Facebook">
                  <SVG name="facebook" label="Facebook" size="md" />
                </a>
              </Link>
              <Link href="https://www.instagram.com/popshelf" prefetch={false}>
                <a target="_blank" className="footer__social-media-icon" aria-label="Popshelf's page on Instagram" >
                  <SVG name="instagram" label="Instagram" size="md" />
                </a>
              </Link>
              <Link href="https://www.pinterest.com/popshelf" prefetch={false}>
                <a target="_blank" className="footer__social-media-icon" aria-label="Popshelf's page on Pinterest">
                  <SVG name="pinterest" label="Pinterest" size="md" />
                </a>
              </Link>
              <Link href="https://www.tiktok.com/@popshelf" prefetch={false}>
                <a target="_blank" className="footer__social-media-icon" aria-label="Popshelf's page on TikTok">
                  <SVG name="tiktok" label="TikTok" size="md" />
                </a>
              </Link>
            </div>
          </div>
          <ul className='footer__section'>
            {footerTemplate?.column1Links && Object.keys(footerTemplate.column1Links).slice(1).map((key) => (
              <li className="footer__page-item" key={footerTemplate.column1Links[key].text}>
                {renderLink(footerTemplate.column1Links[key])}
              </li>
            ))}
          </ul>
          <ul className='footer__section'>
            {footerTemplate?.column2Links && Object.keys(footerTemplate.column2Links).slice(1).map((key) => (
              <li className="footer__page-item" key={footerTemplate.column2Links[key].text}>
                {renderLink(footerTemplate.column2Links[key])}
              </li>
            ))}
          </ul>
        </div>
        <div className="footer__bottom">
          <p>
            © Copyright {currentYear}. pOpshelf&#174; All rights reserved.
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer
