import dayjs from 'dayjs'
import Link from 'next/link'
import React, { useContext, useEffect } from 'react'
import Context from '../context'
import { useChangeCartStore } from '../hooks/cart.hooks'
import SVG from './SVG'
import Image from 'next/image'
import getConfig from 'next/config'

export const StoreDetailsFlyout = ({ dismiss, store }) => {
  const currentDay = dayjs().format('dddd')
  const { publicRuntimeConfig } = getConfig()

  const { state } = useContext(Context)
  const changeCartCall = useChangeCartStore()

  const setStore = (store) => {
    changeCartCall(store.storenumber)
  }
  const address = `pOpshelf, ${store.address}, ${store.city}, ${store.state} ${store.postalcode}`

  useEffect(() => {
    const scheduleTableDays = document.querySelectorAll('.schedule__table-days p')
    const scheduleTableTime = document.querySelectorAll('.schedule__table-time p')
    scheduleTableDays.forEach((day, index) => {
      if (day.innerHTML === currentDay) {
        day.classList.add('bold-style')
        scheduleTableTime[index].classList.add('bold-style')
      }
    })
  }, [])

  const handleGetDirections = () => {
    const encodedAddress = encodeURIComponent(address)
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`
    window.open(mapsUrl, '_blank')
  }

  const storeSelectionButton = () => {
    if (store.storenumber === state.cart.store?.storenumber) {
      return (<button className='btn__primary btn__primary--full' disabled><SVG name="check-green" label="check-green" size="sm" /> SELECTED</button>)
    } else if (store.store_preview) {
      return (<p className="store-search__message-text">coming soon!</p>)
    } else if (store.is_closed) {
      return (<p className="store-search__message-text">temporarily closed</p>)
    } else {
      return (<button className='btn__primary btn__primary--full' onClick={() => setStore(store)}>SELECT</button>)
    }
  }
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${state.cart.store?.latitude},${state.cart.store?.longitude}&zoom=19&size=1200x800&markers=color:red&markers=size:mid&markers=scale:2|${state.cart.store?.latitude},${state.cart.store?.longitude}&scale=2&key=${publicRuntimeConfig.MAPS_API_KEY}`

  return (
    <div className='store-flyout'>
      <button onClick={dismiss} className="store-flyout__close">
        <SVG name="close" label="Close" size="md" />
      </button>
      <div className='store-flyout__info'>
        <h1>{store.address}</h1>
        <h2>{store.city}, {store.state} {store.postalcode}</h2>
        <div className='store-flyout__info-direction'>
          {store.distance && <div className='store-flyout__info-direction-distance'><p>{`${store.distance && store.distance.toFixed(2)} mi.`}</p></div>}
          <a href="#" onClick={handleGetDirections}>Directions</a>
        </div>
        <div className='store-flyout__info-closing'>
          <span></span>
          <p> Closes at {dayjs(store[`hours_${dayjs().format('ddd').toLowerCase()}`].split(':')[1], 'hhmm').format('h A')} today</p>
        </div>
        <div className='store-flyout__info-number'>
          <a href={`tel:${store.phone}`}>({store.phone.slice(0, 3)}) {store.phone.slice(3, 6)}-{store.phone.slice(6, 10)}</a>
        </div>
        <div className='store-flyout__info-store-selection-button'>
            {storeSelectionButton()}
        </div>
        <Link passHref href={`/store/${store.storenumber}`}>
          <a>
            <p onClick={dismiss} className='store-flyout__info-details'>View complete store details</p>
          </a>
        </Link>
      </div>
      <div className='maps__flyout'>
        <Image src={mapUrl} alt='Maps' width={0} height={0} sizes="100vw" />
      </div>
      <div className='schedule'>
        <div className='schedule__heading'>
          <p>Hours:</p>
        </div>
        <div className='schedule__table'>
          <div className='schedule__table-days'>
            <p>Monday</p>
            <p>Tuesday</p>
            <p>Wednesday</p>
            <p>Thursday</p>
            <p>Friday</p>
            <p>Saturday</p>
            <p>Sunday</p>
          </div>
          <div className='schedule__table-time'>
            <p>{dayjs(store.hours_mon.split(':')[0], 'hhmm').format('h:mm A')} - {dayjs(store.hours_mon.split(':')[1], 'hhmm').format('h:mm A')}</p>
            <p>{dayjs(store.hours_tue.split(':')[0], 'hhmm').format('h:mm A')} - {dayjs(store.hours_tue.split(':')[1], 'hhmm').format('h:mm A')}</p>
            <p>{dayjs(store.hours_wed.split(':')[0], 'hhmm').format('h:mm A')} - {dayjs(store.hours_wed.split(':')[1], 'hhmm').format('h:mm A')}</p>
            <p>{dayjs(store.hours_thu.split(':')[0], 'hhmm').format('h:mm A')} - {dayjs(store.hours_thu.split(':')[1], 'hhmm').format('h:mm A')}</p>
            <p>{dayjs(store.hours_fri.split(':')[0], 'hhmm').format('h:mm A')} - {dayjs(store.hours_fri.split(':')[1], 'hhmm').format('h:mm A')}</p>
            <p>{dayjs(store.hours_sat.split(':')[0], 'hhmm').format('h:mm A')} - {dayjs(store.hours_sat.split(':')[1], 'hhmm').format('h:mm A')}</p>
            <p>{dayjs(store.hours_sun.split(':')[0], 'hhmm').format('h:mm A')} - {dayjs(store.hours_sun.split(':')[1], 'hhmm').format('h:mm A')}</p>
          </div>
        </div>
      </div>

    </div>
  )
}
