import React from 'react'

const SVG = ({ name, label, size = null }) => {
  return (
    <svg aria-label={label} className={`icons${size ? ` icons-size--${size}` : ''}`}>
        <use data-testid="use-icon" xlinkHref={`/images/sprite.svg#${name}`}></use>
    </svg>
  )
}

export default SVG
