import Image from 'next/image'

import OopsImage from '../public/images/oops.png'

const ServerErrorModal = ({ handleDismiss }) => {
  return (
    <div>
        <section>
          <div className="ops-modal">
            <Image src={OopsImage} width={175} height={67.5} alt="oops" />
          </div>
          <p>
            We couldn’t pop that page.<br/>Try again.
          </p>
          <button className="btn__primary" onClick={handleDismiss}>
            CLOSE
          </button>
        </section>
    </div>
  )
}

export default ServerErrorModal
