import { createContext, useContext, useState, useRef, useEffect } from 'react'
import lottie from 'lottie-web'
import animation from './bubbles.json'

const LoadingContext = createContext({
  loading: false,
  setLoading: null
})

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const value = { loading, setLoading }
  const animationContainer = useRef()

  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animation,
      loop: -1
    })
  }, [loading])

  return (
    <LoadingContext.Provider value={value}>
        {loading && (
          <div
            className="animation-container"
            style={{
              position: 'fixed',
              zIndex: 10000,
              height: '100vh',
              width: '100vw',
              backgroundColor: 'white',
              top: 0,
              left: 0
            }}
            ref={animationContainer}
          />
        )}
        {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => {
  const context = useContext(LoadingContext)
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider')
  }
  return context
}
