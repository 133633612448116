import React, { useState, useContext, useEffect } from 'react'
import { IonMenuButton, IonTitle, IonToolbar, useIonModal, isPlatform, IonButton, IonButtons } from '@ionic/react'
import { useRouter } from 'next/router'
import Context from '../context'
import Search from './Search'
import { enterAnimation, leaveAnimation } from '../utils/modal-animations'
import SVG from './SVG'

const Header: React.FC = () => {
  const { state } = useContext(Context)
  const router = useRouter()
  const [counterTotal, setCounterTotal] = useState(0)

  useEffect(() => {
    const counter = state.cart.products?.reduce((prev, cur) => {
      return prev + cur.quantity
    }, 0)

    setCounterTotal(counter)
  }, [state.cart])

  const handleDismiss = () => dismiss()
  const [present, dismiss] = useIonModal(Search, { handleDismiss }
  )
  const toggleSearch = async () => {
    present({ cssClass: 'modal--search', enterAnimation, leaveAnimation })
  }

  const isServer = typeof window === 'undefined'
  const isHomePage = useRouter().pathname === '/'
  if (!isServer) {
    return (
      <div className='header'>
        <IonToolbar>
          <IonButtons slot="start">
            {!isHomePage && isPlatform('pwa') && (
              <IonButton onClick={() => router.back()}>
                <SVG name='back' label='back button' size='md' />
              </IonButton>
            )}
            <IonMenuButton menu="mainContentId" autoHide={false}>
              <SVG name='menu' label='menu' size='md' />
              {!isPlatform('pwa') && <p>Menu</p>}
            </IonMenuButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton onClick={toggleSearch}>
              <SVG name='search' label='search' size='md' />
            </IonButton>
            <IonButton onClick={() => router.push('/cart')} className="header__cart">
              {counterTotal > 0 && (
                <div className="header__cart-counter">
                  {counterTotal}
                </div>
              )}
              <SVG name='cart' label='cart' size='md' />
            </IonButton>
          </IonButtons>

          <IonTitle>
            <IonButtons>
            <IonButton onClick={() => router.push('/')}>
              <SVG name='logo-color' label='logo' />
            </IonButton>
            </IonButtons>
          </IonTitle>
        </IonToolbar>
      </div>
    )
  } else {
    return (
      <div className="header"></div>
    )
  }
}

export default Header
