import { createAnimation } from '@ionic/react'

export const enterAnimation = (baseEl: any) => {
  const root = baseEl.shadowRoot

  const backdropAnimation = createAnimation()
    .addElement(root.querySelector('ion-backdrop')!)
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

  const wrapperAnimation = createAnimation()
    .addElement(root.querySelector('.modal-wrapper')!)
    .keyframes([
      { offset: 0, opacity: '0', transform: 'translateX(100%)' },
      { offset: 1, opacity: '0.99', transform: 'translateX(0%)' }
    ])

  return createAnimation()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(250)
    .addAnimation([backdropAnimation, wrapperAnimation])
}

export const leaveAnimation = (baseEl: any) => {
  return enterAnimation(baseEl).direction('reverse')
}

export const enterAnimationLeft = (baseEl: any) => {
  const root = baseEl.shadowRoot

  const backdropAnimation = createAnimation()
    .addElement(root.querySelector('ion-backdrop')!)
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

  const wrapperAnimation = createAnimation()
    .addElement(root.querySelector('.modal-wrapper')!)
    .keyframes([
      { offset: 0, opacity: '0', transform: 'translateX(-100%)' },
      { offset: 1, opacity: '0.99', transform: 'translateX(0%)' }
    ])

  return createAnimation()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(250)
    .addAnimation([backdropAnimation, wrapperAnimation])
}

export const leaveAnimationLeft = (baseEl: any) => {
  return enterAnimationLeft(baseEl).direction('reverse')
}
