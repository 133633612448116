import React from 'react'
import SVG from './SVG'

const Popover = ({ tooltipMsg, onHide, tooltipMsgAlt, altMsg }) => {
  return (
    <div className='popover__container'>
      <p className='popover__container-item'>{altMsg ? tooltipMsgAlt : tooltipMsg}</p>
      <button className='popover__container-close' onClick={onHide} ><SVG name="x-large" label="x-large" size="sm"></SVG> </button>
    </div>
  )
}

export default Popover
