import { useState } from 'react'
import Image from 'next/image'
import { thumbLoader } from '../utils/custom-image-loader'
import StarRating from './StarRating'
import Link from 'next/link'
import SVG from './SVG'

const ProductPreviewCard = ({ data }) => {
  const brokenImage = '/images/popshelf-broken-onwhite.svg'
  const [imageSrc, setImageSrc] = useState(data.image_urls || brokenImage)
  return (

    <div className='product-preview-card'>
      <Link href={`/product/${data.slug}`}>
        <a>
          <div className="product-preview-card__container">
            <div className="product-preview-card__container-image">
              <Image
                src={imageSrc}
                alt={data.product_name}
                width={100}
                height={100}
                objectFit="contain"
                layout="fixed"
                loader={thumbLoader}
                onError={() => setImageSrc(brokenImage)}
              />
            </div>
            <div>
              <h6>{data.product_name}</h6>
              <StarRating id={data.upc} percent={data.avg_rating} />
              <div>
                <p hidden={isNaN(data.price) || data.price === 0}>${parseFloat(data.discount_price ? data.discount_price : data.price).toFixed(2)}</p>
                <SVG name="arrow-right" label="Arrow-right" size="md" />
              </div>
            </div>
          </div>
        </a>
      </Link>
    </div>

  )
}
export default ProductPreviewCard
