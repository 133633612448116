import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { globalHistory } from '@reach/router'
import getConfig from 'next/config'

const reactPlugin = new ReactPlugin()
const { publicRuntimeConfig } = getConfig()
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: publicRuntimeConfig.APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: globalHistory }
    }
  }
})
if (publicRuntimeConfig.APPINSIGHTS_KEY) {
  ai.loadAppInsights()
}
const comp = (Component) => withAITracking(reactPlugin, Component)
export default comp
export const appInsights = ai.appInsights
