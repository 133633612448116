export const writeCookie = (key, value, hours) => {
  const date = new Date()

  // Get milliseconds at current time plus number of hours*60 minutes*60 seconds* 1000 milliseconds
  date.setTime(+date + (hours * 3600000)) // 60 * 60 * 1000

  window.document.cookie = key + '=' + value + '; expires=' + date.toUTCString() + '; path=/'

  return value
}

export const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop().split(';').shift()
  }
}
