import Image from 'next/image'
import PinImage from '../public/images/location_pin.png'

const LocationErrorModal = ({ handleDismiss }) => {
  return (
    <section>
      <div className="location-pin">
        <Image height={60} width={60} src={PinImage} alt="location pin" />
      </div>
      <p>This browser does not have location permissions turned on. Please update and try again.</p>
      <button className="btn__primary" onClick={handleDismiss}>
        CLOSE
      </button>
    </section>
  )
}

export default LocationErrorModal
