import { checkCookie } from './utils/cookies'

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'UPDATE_CART':
      if (typeof Storage !== 'undefined' && checkCookie()) {
        localStorage.setItem('cart', JSON.stringify(action.payload))
      }
      return {
        ...state,
        cart: { ...action.payload }
      }
    case 'ADD_LOCATION':
      if (typeof Storage !== 'undefined' && checkCookie()) {
        localStorage.setItem('location', JSON.stringify(action.payload))
      }
      return {
        ...state,
        location: action.payload
      }
    case 'ADD_GIGYA_SCRIPT':
      return {
        ...state,
        gigyaScript: action.payload
      }
    case 'USER_CUS_DATA':
      if (typeof Storage !== 'undefined' && checkCookie()) {
        localStorage.setItem('user', JSON.stringify(action.payload))
        try {
          const userData = action.payload
          if (userData && userData.authType) {
            window.claripDNSSCustomerData = {
              loggedIn: true,
              source: 'web',
              firstName: userData.firstName,
              lastName: userData.lastName,
              email: userData.email,
              phoneNumber: userData.phone
            }
          }
        } catch (e) {
          console.warn('error parsing localStorage: ', e)
        }
      }
      return {
        ...state,
        user: { ...action.payload }
      }
    case 'SET_ION_CONTENT':
      return {
        ...state,
        ionContent: action.payload
      }
    case 'SHOW_BACK_TO_TOP':
      return {
        ...state,
        showBackToTop: action.payload
      }
    case 'SET_DIGITAL_DATA':
      return {
        ...state,
        digitalData: action.payload
      }
    case 'SET_BRANCH_LOADED':
      return {
        ...state,
        branchLoaded: action.payload
      }
    case 'SET_GOOGLE_LOADED':
      return {
        ...state,
        googleLoaded: action.payload
      }
    case 'SET_ADOBE_LOADED':
      return {
        ...state,
        isAdobeLoaded: action.payload
      }
    case 'SET_CLARIP_LOADED':
      return {
        ...state,
        isClaripLoaded: action.payload
      }
    case 'SET_REGISTERED_LOGIN':
      return {
        ...state,
        registeredLogin: action.payload
      }
    case 'SET_DEFERRED_PROMPT':
      return {
        ...state,
        deferredPrompt: action.payload
      }
    case 'SET_CHECKOUT_INSTALL_MODAL':
      return {
        ...state,
        checkoutInstallModal: action.payload
      }
    case 'ADD_ADOBE_SCRIPT':
      if (typeof Storage !== 'undefined') {
        localStorage.setItem('adobe', JSON.stringify(action.payload))
      }
      return {
        ...state,
        adobe: action.payload
      }
    case 'SET_DEVICE_ID':
      if (typeof Storage !== 'undefined' && checkCookie()) {
        localStorage.setItem('deviceId', action.payload)
      }
      return {
        ...state,
        deviceId: action.payload
      }
    case 'SET_RECENTLY_VIEWED':
      if (typeof Storage !== 'undefined' && checkCookie()) {
        localStorage.setItem('recentlyViewed', JSON.stringify(action.payload))
      }
      return {
        ...state,
        recentlyViewed: action.payload
      }
    case 'SET_RECENTLY_SEARCHED':
      if (typeof Storage !== 'undefined' && checkCookie()) {
        localStorage.setItem('recentlySearched', JSON.stringify(action.payload))
      }
      return {
        ...state,
        recentlySearched: action.payload
      }
    case 'SET_REWARD_DATA':
      if (typeof Storage !== 'undefined' && checkCookie()) {
        localStorage.setItem('loyaltyData', JSON.stringify(action.payload))
      }
      return {
        ...state,
        loyalty: { ...action.payload }
      }
    default:
      return state
  }
}

export default reducer
