const getPathName = (url) => {
  let ext = url.split('.').pop()
  if (ext === 'jpeg') {
    ext = 'jpg'
  }
  return ext
}

const verifyAEMUrl = (url) => {
  return url.includes('dollargeneral.com') || url.includes('popshelf.com')
}

const isDynamicMedia = url => {
  return url.includes('scene7')
}

export const defaultLoader = ({ src, width }) => {
  if (isDynamicMedia(src)) {
    return `${src}?wid=${width <= 768 ? '768' : '1280'}`
  }
  if (!verifyAEMUrl(src)) {
    return `${src}?${width}`
  }
  const ext = getPathName(src)
  return ext !== 'gif' ? width <= 768 ? `${src}.thumb.768.768.${ext}` : `${src}.thumb.1280.1280.${ext}?${width}` : `${src}?${width}`
}

export const thumbLoader = ({ src, width }) => {
  if (isDynamicMedia(src)) {
    return `${src}?wid=200`
  }
  if (!verifyAEMUrl(src)) {
    return `${src}?${width}`
  }
  const ext = getPathName(src)
  return ext !== 'gif' ? `${src}.thumb.200.200.${ext}?${width}` : `${src}?${width}`
}

export const mediumLoader = ({ src, width }) => {
  if (isDynamicMedia(src)) {
    return src
  }
  if (!verifyAEMUrl(src)) {
    return `${src}?${width}`
  }
  const ext = getPathName(src)
  return ext !== 'gif' ? `${src}.thumb.768.768.${ext}?${width}` : `${src}?${width}`
}
